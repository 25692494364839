import React, { useState } from "react";
import './form.css';  // Import your CSS here
import { useNavigate } from "react-router-dom";

const SurveyForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: '',
    phone: '',
    email: '',
    city: '',
    pincode: '',
    budget: '',
    occupation: '',
    companyName: '',
    source: '',
    feedback: '',
    projectName: '',
    commercialType: '',
    area: [],
    finance: '',
    investmentPurpose: '',
    channelPartnerFirmName: '',
    brokerName: '',
    reraNo: '',
    remarks: '',
    hasChannelPartner: '',  
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        area: checked
          ? [...prevData.area, value]
          : prevData.area.filter((platform) => platform !== value)
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation for phone number
    if (formData.phone.length !== 10) {
      alert("Phone number must be 10 digits.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://avenue-backend.vercel.app/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        // Redirect to form done page on successful submission
        setTimeout(() => {
          navigate('/done');  // Use navigate function directly with path
        }, 2000);
      } else {
        alert(`Registration failed: ${result.error}`);
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  return (
<div>
  <div className="TopContainer">
  <div class="logo-container">
    <img src="/avenue.png" alt="Company Logo" id="logo" />
  </div>
  <h1 id="title">AVENUE GROUP CLIENT REGISTRATION SURVEY</h1>
  <p id="description" className="survey-description">
    Thank you for giving us the opportunity to serve you and meet your needs!
  </p>
  </div>
      <form id="survey-form" onSubmit={handleSubmit}>
      <div className="name-container">
  <label id="firstname-label" htmlFor="firstName">
    First Name
    <input
      id="firstName"
      type="text"
      name="firstName"
      value={formData.firstName}
      placeholder="Your First Name"
      onChange={handleInputChange}
      required
    />
  </label>
  <label id="lastname-label" htmlFor="lastName">
    Last Name
    <input
      id="lastName"
      type="text"
      name="lastName"
      value={formData.lastName}
      placeholder="Your Last Name"
      onChange={handleInputChange}
      required
    />
  </label>
</div>


<div className="name-container">
  <label id="dateOfBirth-label" htmlFor="dateOfBirth">
  Date Of Birth
    <input
      id="dateOfBirth"
      type="date"
      name="dateOfBirth"
      value={formData.dateOfBirth}
      placeholder="Your Date Of Birth"
      onChange={handleInputChange}
      required
    />
  </label>
  <label id="phone-label" htmlFor="phone">
  Phone
  <input
    id="phone"
    type="tel"             
    name="phone"
    value={formData.phone}
    placeholder="Your Phone Number"
    onChange={handleInputChange}
    pattern="[0-9]{10}"      
    maxLength="10"           
    required
  />
</label>
</div>

<div className="name-container">
  <label id="occupation-label" htmlFor="occupation">
  Occupation
    <input
      id="occupation"
      type="text"
      name="occupation"
      value={formData.occupation}
      placeholder="Your Occupation"
      onChange={handleInputChange}
      required
    />
  </label>
  <label id="companyName-label" htmlFor="companyName">
  Company Name
  <input
    id="companyName"
    type="text"             
    name="companyName"
    value={formData.companyName}
    placeholder="Your Company Name"
    onChange={handleInputChange}      
    required
  />
</label>
</div>

        <label id="email-label" htmlFor="email">
          Email
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            placeholder="Your email address"
            onChange={handleInputChange}
            required
          />
        </label>

        <label id="address-label" htmlFor="address">
        Address
          <input
            id="address"
            type="text"
            name="address"
            value={formData.address}
            placeholder="Your Full Address"
            onChange={handleInputChange}
            required
          />
        </label>
        <label htmlFor="projectName">
        Project Name
          <select
            id="projectName"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
          >
            <option value="">Select Project</option>
          <option value="Avenue Daffodils">Avenue Daffodils</option>
          <option value="Avenue Imperia">Avenue Imperia</option>
          <option value="66Th Highstreet Square">66Th Highstreet Square</option>
          <option value="Avenue Promenade">Avenue Promenade</option>
          <option value="Avenue Vertex">Avenue Vertex</option>
          <option value="Avenue Cecilia">Avenue Cecilia</option>
          </select>
        </label>


        <label>Commercial Type</label>

<div className="radio-group">
  <label>
    <input
      id="Office"
      type="radio"
      name="commercialType"
      value="Office"
      checked={formData.commercialType === "Office"}
      onChange={handleInputChange}
    />
    Office
  </label>
  <label>
    <input
      id="Shop"
      type="radio"
      name="commercialType"
      value="Shop"
      checked={formData.commercialType === "Shop"}
      onChange={handleInputChange}
    />
    Shop
  </label>
  <label>
    <input
      id="Showroom"
      type="radio"
      name="commercialType"
      value="Showroom"
      checked={formData.commercialType === "Showroom"}
      onChange={handleInputChange}
    />
    Showroom
  </label>
</div>

<label>Area (in Sqft)</label>
        <div className="radio-group">

        
        <label>
          <input
            type="checkbox"
            name="area"
            value="100-500"
            checked={formData.area.includes("100-500")}
            onChange={handleInputChange}
          />
          100 - 500 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="500-1000"
            checked={formData.area.includes("500-1000")}
            onChange={handleInputChange}
          />
          500 - 1000 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="1000-1500"
            checked={formData.area.includes("1000-1500")}
            onChange={handleInputChange}
          />
          1000 - 1500 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="1500-2000"
            checked={formData.area.includes("1500-2000")}
            onChange={handleInputChange}
          />
          1500 - 2000 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="2000-3000"
            checked={formData.area.includes("2000-3000")}
            onChange={handleInputChange}
          />
          2000 - 3000 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="3000-4000"
            checked={formData.area.includes("3000-4000")}
            onChange={handleInputChange}
          />
          3000 - 4000 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="4000-5000"
            checked={formData.area.includes("4000-5000")}
            onChange={handleInputChange}
          />
          4000 - 5000 Sqft
        </label>
        <label>
          <input
            type="checkbox"
            name="area"
            value="5000+"
            checked={formData.area.includes("5000+")}
            onChange={handleInputChange}
          />
          5000+ Sqft
        </label>
        </div>


        <label htmlFor="budget">
        Requirement of Client
          <select
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleInputChange}
          >
            <option value="">Select Budget</option>
          <option value="₹30 Lakhs - ₹50 Lakhs">₹30 Lakhs - ₹50 Lakhs</option>
          <option value="₹50 Lakhs - ₹75 Lakhs">₹50 Lakhs - ₹75 Lakhs</option>
          <option value="₹75 Lakhs - ₹1 Crore">₹75 Lakhs - ₹1 Crore</option>
          <option value="₹1 Crore - ₹2 Crores">₹1 Crore - ₹2 Crores</option>
          <option value="₹2 Crore - ₹3 Crores">₹2 Crore - ₹3 Crores</option>
          <option value="₹3 Crore - ₹4 Crores">₹3 Crore - ₹4 Crores</option>
          <option value="₹4 Crore - ₹5 Crores">₹4 Crore - ₹5 Crores</option>
          <option value="₹5 Crore - Above">₹5 Crore - Above</option>
          </select>
        </label>

        <label>Finance</label>
<div className="radio-group">
  <label>
    <input
      id="Loan"
      type="radio"
      name="finance"
      value="Loan"
      checked={formData.finance === "Loan"}
      onChange={handleInputChange}
    />
    Loan
  </label>
  <label>
    <input
      id="Own Finance"
      type="radio"
      name="finance"
      value="Own Finance"
      checked={formData.finance === "Own Finance"}
      onChange={handleInputChange}
    />
    Own Finance
  </label>
</div>



<label>Investment Purpose</label>
<div className="radio-group">
  <label>
    <input
      id="Personal Use"
      type="radio"
      name="investmentPurpose"
      value="Personal Use"
      checked={formData.investmentPurpose === "Personal Use"}
      onChange={handleInputChange}
    />
    Personal Use
  </label>
  <label>
    <input
      id="Leasing"
      type="radio"
      name="investmentPurpose"
      value="Leasing"
      checked={formData.investmentPurpose === "Leasing"}
      onChange={handleInputChange}
    />
    Leasing
  </label>
</div>



<label htmlFor="source">
        Enquiry Source
          <select
            id="source"
            name="source"
            value={formData.source}
            onChange={handleInputChange}
          >
            <option value="">Select Source</option>
          <option value="Digital marketing">Digital marketing</option>
          <option value="Social marketing">Social marketing</option>
          <option value="Website">Website</option>
          <option value="Hordings">Hordings</option>
          <option value="Pre-Sales">Pre-Sales</option>
          <option value="Exhibition">Exhibition</option>
          <option value="Channel Partners">Channel Partners</option>
          <option value="Referral">Referral</option>
          <option value="Leaflets">Leaflets</option>
          </select>
        </label>


        <label>Is there a Channel Partner?</label>
<div className="radio-group">
  <label>
    <input
      id="yes"
      type="radio"
      name="hasChannelPartner"
      value="yes"
      checked={formData.hasChannelPartner === "yes"}
      onChange={handleInputChange}
    />
    yes
  </label>
  <label>
    <input
      id="no"
      type="radio"
      name="hasChannelPartner"
      value="no"
      checked={formData.hasChannelPartner === "no"}
      onChange={handleInputChange}
    />
    no
  </label>
</div>

{formData.hasChannelPartner === 'yes' && (
  <>
    <label id="channelPartnerFirmName-label" htmlFor="channelPartnerFirmName">
    Channel Partner Firm Name
          <input
            id="channelPartnerFirmName"
            type="text"
            name="channelPartnerFirmName"
            value={formData.channelPartnerFirmName}
            placeholder="Your Firm Name"
            onChange={handleInputChange}
            required
          />
        </label>
        <label id="brokerName-label" htmlFor="brokerName">
        Broker Name
          <input
            id="brokerName"
            type="text"
            name="brokerName"
            value={formData.brokerName}
            placeholder="Your Broker Name"
            onChange={handleInputChange}
            required
          />
        </label>
        <label id="reraNo-label" htmlFor="reraNo">
        RERA No
          <input
            id="reraNo"
            type="number"
            name="reraNo"
            value={formData.reraNo}
            placeholder="Your RERA NO"
            onChange={handleInputChange}
            required
          />
        </label>
    <label className="remarks">
    Remarks?
          <textarea
            rows="6"
            cols="48"
            name="remarks"
            value={formData.remarks}
            onChange={handleInputChange}
            placeholder="Enter them here..."
          />
        </label>
  </>
)}



        
<button id="submit" type="submit">
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      <footer>
        <p>
        Designed in <a href="https://www.avenuegroup.co/">Avenue Housing Pvt Ltd.</a>
        </p>
      </footer>
    </div>
  );
};

export default SurveyForm;
