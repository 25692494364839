import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SurveyForm from './form'; 
import AdminPanel from './panel';
import FormDone from "./done";
import SendEmailPage from './email';
// import SurveyForm from './temp';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SurveyForm/>}/>
          <Route path="/panel" element={<AdminPanel/>}/>
          <Route path="/done" element={<FormDone/>}/>
          <Route path="/email" element={<SendEmailPage />} />
          {/* <Route path="/temp" element={<SurveyForm/>}/> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;