import React from "react";
import { Link } from "react-router-dom";
import './done.css';  // Import your CSS here

const FormDone = () => {
  return (
    <div className="form-done">
      <h1>Thank You!</h1>
      <img src="/FormDone.jpg" alt="Thank You" className="thank-you-image" />
      <p>Your form has been successfully submitted. We will get in touch with you soon!</p>
      <Link to="/">Submit Another Form</Link>
    </div>
  );
};

export default FormDone;
