import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './panel.css';

const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // New state for sorting
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://avenue-backend.vercel.app/')
      .then(response => {
        const fetchedData = response.data.data; // Access the 'data' property
        if (Array.isArray(fetchedData)) {
          setData(fetchedData);
          setFilteredData(fetchedData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    // Apply sorting when data or sortConfig changes
    const sortedData = [...filteredData];
    if (sortConfig.key !== null) {
      sortedData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    setFilteredData(sortedData);
  }, [sortConfig]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === '') {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter(item =>
          `${item[2]} ${item[3]}`.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item[7].toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const goToEmailPage = () => {
    navigate('/email');
  };

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by Name or Email"
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />

      {/* Navigate to email page */}
      <button onClick={goToEmailPage} className="send-email-btn">
        Go to Email Page
      </button>

      <table className="data-table">
        <thead>
          <tr>
            <th>Enquire No</th>
            <th>Date</th>
            <th>Full Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th onClick={() => handleSort(8)} style={{ cursor: 'pointer' }}>
              Budget {sortConfig.key === 8 ? (sortConfig.direction === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th onClick={() => handleSort(14)} style={{ cursor: 'pointer' }}>
              Area (in Sqft) {sortConfig.key === 14 ? (sortConfig.direction === 'asc' ? '🔼' : '🔽') : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item[0]}</td>
              <td>{item[1]}</td>
              <td>{`${item[2]} ${item[3]}`}</td>
              <td>{item[6]}</td>
              <td>{item[7]}</td>
              <td>{item[8]}</td>
              <td>{item[14]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPanel;
