import React, { useState } from 'react';
import axios from 'axios';

const EmailForm = () => {
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner when submission starts

    // Create FormData to handle file upload
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('text', text); // Just sending plain text, backend will format it
    formData.append('attachment', attachment);

    try {
      const response = await axios.post('https://avenue-backend.vercel.app/email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage(response.data.message || 'Emails sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      setMessage('Error sending emails');
    } finally {
      setLoading(false); // Hide spinner after processing
    }
  };

  return (
    <div className="container">
      <h1>Send Email with Attachment</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="text">Email Content:</label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Write your message here..."
            required
          ></textarea>
        </div>
        <div>
          <label htmlFor="attachment">Attachment:</label>
          <input
            type="file"
            id="attachment"
            onChange={(e) => setAttachment(e.target.files[0])}
          />
        </div>
        <button type="submit">Send Email</button>
      </form>
      {message && <p>{message}</p>}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Sending email... This might take a few minutes.</p>
        </div>
      )}
    </div>
  );
};

export default EmailForm;
